import Project1 from "./Project1";

function Projects() {
  return (
    <section className="projects">
      <Project1 id="project1" />
    </section>
  );
}

export default Projects;
